import { mv } from './modules/mv';
import { smooth } from './modules/smooth';
import { scrollmagic } from './modules/scrollmagic';
import { scrollAction } from './modules/scrollAction';
import { product } from './modules/product';
import { slideVoice } from './modules/slideVoice';
import { header } from './modules/header';

window.addEventListener('load', () => {
  //scrollに応じてアニメーションしたい時
  scrollmagic();
  
  //scrollによる処理を間引きしたい時
  scrollAction();
});

mv();
smooth();
product();
slideVoice();
header();