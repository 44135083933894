export function mv() {
  const mv_slider = document.querySelector('.p-kv_slider');
  const slide_bar = document.querySelector('.p-kv_progress--bar span');
  const slide_bar_current = document.querySelector('.p-kv_progress--current');
  const slide_bar_next = document.querySelector('.p-kv_progress--next');
  const speed = 4000;
  const progress_change = () => {
    const current_slide = mv_slider.querySelector('.swiper-slide-active');
    const current_slide_index = current_slide.dataset.index;
    const next_slide = mv_slider.querySelector('.swiper-slide-next');
    const next_slide_index = next_slide.dataset.index;
    slide_bar_current.innerHTML = current_slide_index;
    slide_bar_next.innerHTML = next_slide_index;
  }

  if(mv_slider) {
    var swiper = new Swiper(mv_slider, {
      allowTouchMove: false,
      loop: true,
      autoplay: {
        delay: speed,
        disableOnInteraction: false,
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      followFinger: false,
      loopAdditionalSlides: 1,
      speed: 1000,
      on: {
        slideChangeTransitionStart: function () {
          slide_bar.style.transitionDuration = '0s',
          slide_bar.style.transform = 'scaleX(0)'
          progress_change();
        },
        slideChangeTransitionEnd: function () {
          slide_bar.style.transitionDuration = speed + 'ms',
          slide_bar.style.transform = 'scaleX(1)'
        },
       }
    });
  }

}