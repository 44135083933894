export function slideVoice() {
  const slide = document.querySelector('.js-slideVoice');

  if(slide) {
    const mySwiper = new Swiper(slide, {
      // Optional parameters
      loop: true,
      loopAdditionalSlides:1,
      slidesPerView: 2,
      spaceBetween:10,
      breakpoints: {
        768: {
          slidesPerView: 3,
          spaceBetween:20,
        },
        1001: {
          slidesPerView: 4,
          spaceBetween:20,
        },
      },
     
      // Navigation arrows
      navigation: {
        nextEl: '.p-voice_slide .swiper-button-next',
        prevEl: '.p-voice_slide .swiper-button-prev',
      },

      autoplay: { // 自動再生させる
        delay: 15000, // 次のスライドに切り替わるまでの時間（ミリ秒）
        disableOnInteraction: false, // ユーザーが操作しても自動再生を止めない
        waitForTransition: false, // アニメーションの間も自動再生を止めない（最初のスライドの表示時間を揃えたいときに）
      },
    });
  }
}