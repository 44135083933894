export function product() {
  const productElements = document.querySelectorAll('.js-product');
  if (productElements) {
    for (let index = 0; index < productElements.length; index++) {
      const productElement = productElements[index];

      const quantity = productElement.querySelector('input[name="product_num"]');
      if(quantity) {
        quantity.after('個');
      }

      const forms = productElement.querySelectorAll('form');
      for (let index = 0; index < forms.length; index++) {
        const form = forms[index];
        form.setAttribute('target','_blank');
      }

      const submitBtn = productElement.querySelector('input[type="submit"]');
      if(!(submitBtn)) {
        const errorTxt = productElement.querySelector('.c-cart_soldout');
        errorTxt.classList.remove('displayNone');
      }
    }
  }

  const slides = document.querySelectorAll('.js-productSlide');
  if(slides) {
    for (let index = 0; index < slides.length; index++) {
      const slide = slides[index];
      const mySwiper = new Swiper(slide, {
        // Optional parameters
        slidesPerView: 1,
        loop: true,
        loopAdditionalSlides: 1,
       
        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    }
  }
}