import { throttle } from 'lodash';

export function scrollAction() {
  //header_scroll
  const headerTrigger = document.querySelector('.js-headerTrigger');
  const headerElement = document.querySelector('.l-header');
  const header_scroll = () => {
    if ( headerTrigger.classList.contains('is-scrolled') ) {
      headerElement.classList.add('is-active');
    } else {
      headerElement.classList.remove('is-active');
    }
  }
  window.addEventListener(
    'scroll',
    _.throttle(
      e => {
        // ここに処理

        //header_scroll
        header_scroll();
        return false;
      },
      50,
      { trailing: true, leading: true }
    )
  );
}