export function header() {
  const header = document.getElementById('header');

  const elm = document.querySelector('.js-kv');
  const elmHeight = elm.clientHeight;


  window.addEventListener('load', () => {
    var y = window.pageYOffset ;
    if (y > elmHeight) {
      header.classList.add('is-active');
    }
  });

  //hamburger
  const hamburger = document.querySelector('.js-hamburger');
  const body = document.querySelector('body');
  if(hamburger) {
    hamburger.addEventListener('click', () => {
      header.classList.toggle('is-active02');
      body.classList.toggle('is-noscroll');
    })
  }

  const anchors = document.querySelectorAll('.l-headerMenu a');
  for (let index = 0; index < anchors.length; index++) {
    const anchor = anchors[index];
    anchor.addEventListener('click', () => {
      header.classList.remove('is-active02');
      body.classList.remove('is-noscroll');
    });
  }
}